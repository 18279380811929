import { isObject, deepMerge } from '@wl/utils'
import { HttpError } from './errors'
import { replaceUrlDynamicParts, createUrlWithParams } from './utils'

export interface ResponseMethods<ResponseBody> {
  arrayBuffer(): Promise<ArrayBuffer>
  blob(): Promise<Blob>
  formData(): Promise<FormData>
  json(): Promise<ResponseBody>
  text(): Promise<string>
}

export type TypedResponse<ResponseBody> = ResponseMethods<ResponseBody> &
  Response

// prettier-ignore
type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace' | 'head'

type CombinationType = 'overwrite' | 'chain'

export interface BeforeFetchContext {
  url: string
  options: RequestInit
}

export interface AfterParseContext<ResponseBody = unknown> {
  response: TypedResponse<ResponseBody>
  data: ResponseBody | string | FormData | ArrayBuffer | Blob
}

export interface HttpClientOptions<
  RequestBody,
  ResponseBody = unknown,
  RequestParams = Record<string, unknown>,
  PathParams = Record<string, unknown>,
> {
  /**
   * HTTP метод.
   *
   * Метод может быть как в нижнем, так и в верхнем регистре.
   *
   * @default 'get'
   */
  method?: HttpMethod | Uppercase<HttpMethod>
  /**
   * Объект с заголовками для вашего запроса.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Headers
   */
  headers?: HeadersInit
  /**
   * Тело запроса.
   * Такие методы как GET и HEAD не могут содержать это свойство и в рантайме будет выброшена ошибка.
   */
  body?: RequestBody
  /**
   * query params запроса
   * @deprecated
   */
  params?: RequestParams
  /**
   * query params запроса
   */
  query?: RequestParams
  /**
   * path params запроса
   */
  paths?: PathParams
  /**
   *
   * request интерцептор, пример:
   * beforeFetch({ url, options }) {
   *   const headers = options.headers;
   *   header.set('X-KEK', 'LOL')
   *
   *   return {
   *     options
   *   }
   * }
   *
   * -- где options - это настройки реквеста fetch
   */
  beforeFetch?: (
    context: BeforeFetchContext,
  ) => Promise<BeforeFetchContext> | BeforeFetchContext
  /**
   *
   * response интерцептор, пример:
   * afterFetch(response) {
   *   const { status } = response
   *   if(status === 403) alert('need login!')
   * }
   *
   * // можно результат не возвращать, но если мы изменяем ответ в этом коллбэке, то результат из коллбэка нужно вернуть
   * return response
   *
   * -- где response - это сырой ответ fetch
   */
  afterFetch?: (context: Response) => Promise<Response> | Response

  /**
   *
   * afterParse интерцептор
   * этот интерцептор служит для подмены body ответа от fetch, в качестве аргумента принимает:
   * 1) resposne - сырой fetch ответ
   * 2) data - распарсенный ответ body
   *
   * интерцептор доджен вернуть data(новую или старую)
   *
   * afterParse({ response, data }) {
   *   if(data.success) {
   *     return {
   *       success: false
   *     }
   *   }
   *
   *   return data;
   * }
   */
  afterParse?: (
    context: AfterParseContext<ResponseBody>,
  ) => Promise<ResponseBody>
  /**
   *
   * afterError интерцептор
   * принимает в качестве аргумента сырой Response
   */
  afterError?: (context: Response) => Promise<void> | void
  credentials?: 'include' | 'omit' | 'same-origin'
}

/**
 * `fetch -> parse` — вариант запроса, в котором мы сначала получаем ответ, а затем обрабатываем данные.
 *
 * ```ts
 * const response = await httpClient(url, options)
 *
 * const responseData = await response.json()
 * ```
 *
 * `fetch + parse` — вариант запроса, в котором нам не требуется работа с атрибутами ответа,
 * например заголовками, мы хотим сразу обработать ответ.
 *
 * ```ts
 * const responseData = await httpClient(url, options).json()
 * ```
 */
export type HttpClientReturn<ResponseBody> = ResponseMethods<ResponseBody> &
  Promise<
    ResponseMethods<ResponseBody> &
      Omit<Response, keyof ResponseMethods<ResponseBody>>
  >

export function httpClient<
  RequestBody extends BodyInit | object | null,
  ResponseBody,
  RequestParams = Record<string, unknown>,
  PathParams = Record<string, unknown>,
>(
  url: string,
  options?: HttpClientOptions<
    RequestBody,
    ResponseBody,
    RequestParams,
    PathParams
  >,
): HttpClientReturn<ResponseBody> {
  const defaultRequestOptions = {
    method: 'get',
  }
  const preConfigurableRequestOptions = {
    body: isValidBody(options?.body) ? options?.body : null,
  }
  const requestInit: RequestInit = Object.assign(
    defaultRequestOptions,
    options,
    preConfigurableRequestOptions,
  )
  requestInit.headers = new Headers(options?.headers)
  requestInit.method = requestInit.method!.toUpperCase()

  if (guessSimpleObject(options?.body)) {
    requestInit.body = JSON.stringify(options?.body)
    requestInit.headers?.set('Content-Type', 'application/json')
  }

  let response: TypedResponse<ResponseBody>

  const preparedUrlByDynamicParams = replaceUrlDynamicParts<PathParams>({
    url,
    paths: options?.paths,
  })

  async function fetcher() {
    let context: BeforeFetchContext = {
      url: createUrlWithParams({
        url: preparedUrlByDynamicParams,
        params: options?.query || options?.params,
      }),
      options: requestInit,
    }

    if (options?.beforeFetch) {
      context =
        (await options.beforeFetch({
          url: context.url,
          options: requestInit,
        })) ?? context
    }

    response = await fetch(context.url, context.options)

    if (options?.afterFetch) {
      response = (await options.afterFetch(response)) ?? response
    }

    response.arrayBuffer = withCustomResponseMethod(response.arrayBuffer)
    response.blob = withCustomResponseMethod(response.blob)
    response.formData = withCustomResponseMethod(response.formData)
    response.json = withCustomResponseMethod(response.json)
    response.text = withCustomResponseMethod(response.text)

    // see: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
    if (!response.ok) {
      if (options?.afterError) {
        await options.afterError(response)
      }
      throw new HttpError(response)
    }

    return response
  }

  function withCustomResponseMethod(
    cb: () => Promise<ResponseBody>,
  ): () => Promise<ResponseBody>
  function withCustomResponseMethod(
    cb: () => Promise<string>,
  ): () => Promise<string>
  function withCustomResponseMethod(
    cb: () => Promise<FormData>,
  ): () => Promise<FormData>
  function withCustomResponseMethod(
    cb: () => Promise<Blob>,
  ): () => Promise<Blob>
  function withCustomResponseMethod(
    cb: () => Promise<ArrayBuffer>,
  ): () => Promise<ArrayBuffer>

  function withCustomResponseMethod(
    cb: () => Promise<ResponseBody | string | FormData | ArrayBuffer | Blob>,
  ): () => Promise<ResponseBody | string | FormData | ArrayBuffer | Blob> {
    return async () => {
      let data = await cb.call(response)

      if (!response) {
        throw new Error('response не может быть null на данном этапе')
      }

      if (options?.afterParse) {
        data = await options.afterParse({ response, data })
      }

      return data
    }
  }

  const pipe = new Promise((resolve, reject) => {
    fetcher().then(resolve, reject)
  }) as HttpClientReturn<ResponseBody>

  // response method
  pipe.arrayBuffer = async () => pipe.then(() => response.arrayBuffer())
  pipe.blob = async () => pipe.then(() => response.blob())
  pipe.formData = async () => pipe.then(() => response.formData())
  pipe.json = async () => pipe.then(() => response.json())
  pipe.text = async () => pipe.then(() => response.text())

  return pipe
}

/**
 * Проверяет, что тело запроса является одним из допустимых значений — Blob, ArrayBuffer, TypedArray, DataView, FormData,
 * URLSearchParams, string object или literal (plan text), или a ReadableStream object.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/fetch#body
 * @param value тело запроса
 */
function isValidBody(value: unknown): value is BodyInit | null {
  const instanceType = Object.prototype.toString.call(value)

  switch (instanceType) {
    case '[object Undefined]':
    case '[object Object]':
    case '[object Array]':
    case '[object Null]':
    case '[object FormData]':
    case '[object URLSearchParams]':
    case '[object Blob]':
    case '[object ArrayBuffer]':
    case '[object String]': {
      return true
    }

    default: {
      throw new Error(
        `Request body has incorrect instance type — ${instanceType}`,
      )
    }
  }
}

/**
 * Функция проверяет, является ли тело запроса simple object, который можно преобразовать в string object (JSON).
 *
 * simple object в контексте httpClient используется для:
 * - для преобразования к string object (JSON) методом JSON.stringify,
 * - установки заголовка Content—Type: application/json.
 *
 *  @param value тело запроса
 */
function guessSimpleObject(value: unknown): boolean {
  if (!isObject(value)) {
    return false
  }

  switch (Object.prototype.toString.call(value)) {
    case '[object Null]':
    case '[object FormData]':
    case '[object URLSearchParams]':
    case '[object Blob]':
    case '[object ArrayBuffer]':
    case '[object String]': {
      return false
    }
  }

  return true
}

export interface CreateHttpClientOptions {
  /**
   * The base URL that will be prefixed to all urls
   */
  baseUrl?: string
  headers?: HeadersInit
  credentials?: 'include' | 'omit' | 'same-origin'
  combination?: CombinationType
  beforeFetch?: (
    context: BeforeFetchContext,
  ) => Promise<BeforeFetchContext> | BeforeFetchContext
  afterFetch?: (context: Response) => Promise<Response> | Response
  afterParse?: (context: AfterParseContext<never>) => Promise<unknown> | unknown
  afterError?: (context: Response) => void
}

export function createHttpClient(instanceOptions?: CreateHttpClientOptions) {
  function factoryHttpClient<
    RequestBody extends BodyInit | object | null,
    ResponseBody,
  >(
    url: string,
    options?: HttpClientOptions<RequestBody, ResponseBody>,
  ): HttpClientReturn<ResponseBody> {
    const computedUrl = instanceOptions?.baseUrl
      ? `${instanceOptions.baseUrl}${url}`
      : url
    const computedOptions = deepMerge(instanceOptions || {}, options || {})
    computedOptions.beforeFetch = combineInterceptorsCallbacks(
      instanceOptions?.combination,
      [instanceOptions?.beforeFetch, options?.beforeFetch],
    )
    computedOptions.afterFetch = combineInterceptorsCallbacks(
      instanceOptions?.combination,
      [instanceOptions?.afterFetch, options?.afterFetch],
    )
    computedOptions.afterParse = combineInterceptorsCallbacks(
      instanceOptions?.combination,
      [instanceOptions?.afterParse, options?.afterParse],
    )
    computedOptions.afterError = combineInterceptorsCallbacks(
      instanceOptions?.combination,
      [instanceOptions?.afterError, options?.afterError],
    )
    return httpClient(computedUrl, computedOptions)
  }

  return factoryHttpClient
}

/**
 * @description - ф-я комбинирования интерцепторов. Т.е мы можем использовать несколько интерцепторов, например, задаем общий
 * интерцептор на стадии создания шттп клиента и непосредственно при использовании самого шттп клиента.
 * Эта функция рулит тем, как комбинировать эти интерцепторы: соединяем их либо перезаписываем (используем последний)
 * @param combinationType
 * @param interceptors
 */
function combineInterceptorsCallbacks<T>(
  combinationType: CombinationType = 'chain',
  interceptors?: unknown[],
) {
  const preparedInterceptors = interceptors?.filter(Boolean) || []

  if (!preparedInterceptors.length) {
    return
  }

  if (combinationType === 'overwrite') {
    return async (context: T) => {
      const getLastInterceptor = preparedInterceptors.at(-1)
      if (typeof getLastInterceptor === 'function') {
        return await getLastInterceptor(context)
      }
    }
  }

  return async (context: T) => {
    for (const interceptor of preparedInterceptors) {
      if (typeof interceptor === 'function') {
        context = await interceptor(context)
      }
    }

    return context
  }
}
