import { isObject } from '../isObject'

/**
 * Checks if value is a plain object, that is, an object created by the Object constructor or one with a [[Prototype]] of null.
 *
 * @param value the value to check.
 * @return is a plain object or not
 */
export function isPlainObject<T>(value: T): value is T {
  if (!isObject(value)) {
    return false
  }

  if (Array.isArray(value)) {
    return false
  }

  const prototype = Object.getPrototypeOf(value)
  return prototype.constructor === Object && prototype === Object.prototype
}
