import { resolveInstance } from './helpers'

export const languages = resolveInstance({
  kz: ['kk', 'ru'],
  by: ['ru', 'en'],
})

export const allowedActualLanguagesSortedByPriority = resolveInstance({
  by: ['en', 'ru'],
  kz: ['ru', 'en'],
})
