import { isPlainObject } from '../isPlainObject'

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface IObject {
  [key: string]: any
}

type TUnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never

const merge = <T extends IObject[]>(
  ...objects: T
): TUnionToIntersection<T[number]> =>
  objects.reduce((result, current) => {
    if (Array.isArray(current)) {
      throw new TypeError(
        'Arguments provided to deepmerge must be objects, not arrays.',
      )
    }

    const objectKeys = Object.keys(current)

    if (!objectKeys.length) {
      return result
    }

    objectKeys.forEach((key) => {
      if (Array.isArray(result[key]) && Array.isArray(current[key])) {
        result[key] = merge.options.mergeArrays
          ? Array.from(new Set((result[key] as unknown[]).concat(current[key])))
          : current[key]
      } else if (isPlainObject(result[key]) && isPlainObject(current[key])) {
        result[key] = merge(result[key] as IObject, current[key] as IObject)
      } else {
        result[key] = current[key]
      }
    })

    return result
  }, {}) as any

interface IOptions {
  mergeArrays: boolean
}

const defaultOptions: IOptions = {
  mergeArrays: true,
}

merge.options = defaultOptions

merge.withOptions = <T extends IObject[]>(
  options: Partial<IOptions>,
  ...objects: T
) => {
  merge.options = {
    mergeArrays: true,
    ...options,
  }

  const result = merge(...objects)

  merge.options = defaultOptions

  return result
}

export const deepMerge = merge
