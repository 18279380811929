export class HttpError extends Error {
  response: Response

  constructor(response: Response) {
    const { status } = response

    const reason = status ? `status code ${status}` : 'an unknown error'

    super(`Request failed with ${reason}`)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError)
    }

    this.name = 'HttpError'
    this.response = response
  }
}

export function isHttpError(error: unknown): error is HttpError {
  return error instanceof HttpError
}
