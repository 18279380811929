export function defineLocale(allowedLanguages: string[]) {
  const browserLanguage = window.navigator.language
  const cachedLanguage = localStorage
    .getItem('ls-cache:/:lang')
    ?.replace(/"/gi, '')

  let language = cachedLanguage || browserLanguage || allowedLanguages[0]
  language = language.slice(0, 2).toLowerCase()

  /** Для обратной совместимости */
  if (language === 'kz') {
    language = 'kk'
  }

  return allowedLanguages.includes(language) ? language : allowedLanguages[0]
}
