interface EnvJsConfig {
  INSTANCE: 'kz' | 'by'
  ENV_MODE: 'dev' | 'stage' | 'production'
  ADMIN: {
    HTTP: string
  }
  API: {
    HTTP: string[] // ['https://winlineone1.com/api']
    WS: string[] // ['wss://ws.winlineone1.com/']
    STATIC: string // s3 storage url
  }
  SPORTSBOOK: {
    API: string // 'https://winlineone1.com/sb/api'
    KEY: string // 'a51027e75aa7f4bc7692b450aafe9aea',
    USER: string // 'winlinekz'
    CACHE?: string // 'https://by.dev.sxl.bet/cache'
    // TODO Почему этого поля нет в проде?
    WS?: string // 'https://winlineone1.com/sb/api'
    BINARY?: string
    BINARY_VERSION?: number
  }
  MK_VERSION: number // version of marketbook config
  MFC_VERSION: number // version of marketsFilter config
  FBC_VERSION: number // version of fastBetConfig
  SENTRY?: {
    DSN: string // 'https://26ac114e40894232b106515835a00ee0@o1205402.ingest.sentry.io/6342191'
    ENV: 'development' | 'production'
  }
  RECAPTCHA_KEY?: string // '6LfLpDIeAAAAALj-0AT95sTOlrJzztqoje2oqvNC'
  GRAPH_PROVIDER_KEY: string // ранее хранился в GitLab ENV, теперь переехал в Vault
  NEW_PAYMENT_FLOW: boolean // будут ли работать ввод и вывод через развесовку
  PAYMENT_TOKENIZATION: boolean // токенизация карт

  // Аналитика.
  IPQS?: string
  ANALYTICS_ENABLED?: boolean // gtm, partner
  DOCS: string // cloudflare - https://{deploy-name}.sxl-static.pages.dev/
  MBX5?: string
  COUPON_WELCOME_FREEBET_ENABLED?: boolean // флаг для включения функционала в купоне по приветственному фрибету, есть только на KZ
  POPUP_WELCOME_FREEBET_ENABLED?: boolean // флаг для изменения работы модальных окон при механике 'sultan' на KZ
  IAS_SDK_TOKEN: string // токен для работы SDK https://inappstory.ru
  IAS_API_TOKEN: string // токен для работы REST API https://inappstory.ru
  PAYDALA_URL: string // Url сервиса paydala
  PAYDALA_APP_DOWNLOAD_TEXT: Record<string, string> // текст для деск баннера пэйдалы
  REDIRECT_AUTH_ENABLED: boolean // флаг отвечающий за редирект на форму авторизации при DDOS

  // Unleash Provider
  UNLEASH?: {
    URL: string
    CLIENT_KEY: string
    APP_NAME: 'kz' | 'by'
    ENVIRONMENT: 'development' | 'production'
  }

  YANDEX_CAPTCHA_KEY?: string
  CYBER_WIDGET_URL?: string
}

/**
 * Единая, типизированная точка входя для ENV.js и import.meta.env.
 */
export class Env {
  private static instance: Env | null
  config: EnvJsConfig & ImportMetaEnv

  private constructor() {
    let envJsConfig = globalThis.ENV as EnvJsConfig

    if (import.meta.env.VUE_ENV_CONFIG) {
      envJsConfig = JSON.parse(import.meta.env.VUE_ENV_CONFIG)

      console.warn(
        'Включен режим мокирования ENV.js. Значение конфига установлено из import.meta.env.VUE_ENV_CONFIG \n' +
          `в файле .env.${import.meta.env.MODE}.local в корне вашего проекта.`,
      )
    }

    this.config = { ...envJsConfig, ...import.meta.env }

    // Сохраняем обратную совместимость
    if (!this.config.GRAPH_PROVIDER_KEY) {
      this.config.GRAPH_PROVIDER_KEY = 'sportradar'
    }
  }

  static destroy() {
    Env.instance = null
  }

  static getInstance(): Env {
    if (!Env.instance) {
      Env.instance = new Env()
    }

    return Env.instance
  }

  /**
   * Шорткат для получения объекта с переменными.
   *
   * ```ts
   * import { Env } from '@wl/env'
   *
   * Env.get.API.HTTP[0] // api-сервера
   * ```
   */
  static get get() {
    return Env.getInstance().config
  }
}
