/**
 * @description - по сути функция бесоплезная и нужна ли для сокращения пути до локали.
 * т.е у роллапа есть ограничение на путь динамического импорта, получается что я не могу использовать алиас вначале пути,
 * и поэтому чтобы не писать относительные пути - вынес сюда
 * @param locale
 */
export async function dynamicLocaleImport(locale: string) {
  const data = await import(`../defaultTranslations/${locale}.json?raw`)
  return JSON.parse(data.default)
}
