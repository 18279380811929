export function loadLocale(locale: string) {
  switch (locale) {
    case 'kk':
      return import('dayjs/locale/kk.js')
    case 'ru':
      return import('dayjs/locale/ru.js')
    case 'uz':
      return import('dayjs/locale/uz.js')
    case 'en':
      return import('dayjs/locale/en.js')
  }
}
