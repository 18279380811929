import { isObject, isEmptyObject } from '@wl/utils'

/**
 *
 * ищет динамические части урла и заменяет их на значения из объекта pathParams
 * например, когда есть url: https://lol.ru/{kek},
 * то передав следующий params: { kek: 1, id: 2 }, url станет https://lol.ru/1?id=2
 */
export function replaceUrlDynamicParts<T>({
  url,
  paths,
}: {
  url: string
  paths?: T
}) {
  if (!isObject(paths) || isEmptyObject(paths)) {
    return url
  }

  const hasDynamicParts = url.match(/\{\w+}/g)

  if (!hasDynamicParts) {
    return url
  }

  const { url: preparedUrl } = hasDynamicParts.reduce(
    (acc, dynamicParam) => {
      const preparedParam = dynamicParam.replace(/[{,}]/g, '')

      if (!(preparedParam in paths)) {
        return acc
      }

      acc.url = acc.url.replace(dynamicParam, String(paths[preparedParam]))

      delete acc.paths[preparedParam]

      return acc
    },
    { url, paths },
  )

  return preparedUrl
}

/**
 * ф-я комбинирования query params
 * например имеется url https://vk.com?id=1 и переданный объект { isGuest: 1 } => https://vk.com?id=1&isGuest=1
 */
export function createUrlWithParams<T>({
  url,
  params,
}: {
  url: string
  params?: T
}): string {
  if (!params) {
    return url
  }
  // ts хочет в url search params видеть стринг в качестве значения, но у js свое видение на этот счет...
  // p.s скормить можно и число и буль и т.д
  const queryParams = new URLSearchParams(params as Record<string, string>)
  const urlObject = new URL(url)
  const existingParams = urlObject.searchParams

  const originURL = `${urlObject.origin}${urlObject.pathname}`
  existingParams.forEach((value, key) => {
    queryParams.append(key, value)
  })

  const queryParamsString = queryParams.toString()

  return !queryParamsString
    ? originURL
    : `${originURL}?${queryParams.toString()}`
}
