import { Env } from '@wl/env'

const allowedInstanceCodes = ['by', 'kz']

export type InstanceCode = 'by' | 'kz'

function getInstanceCodeFromUrl() {
  const isBrowser = typeof window !== 'undefined' && window.location

  if (!isBrowser) {
    return 'kz'
  }

  const globalsString =
    new URLSearchParams(window.location.search).get('globals') ?? ''
  const globals = Object.fromEntries(
    globalsString.split(';').map((v) => v.split(':')),
  )

  const instance = globals['instance']

  const result =
    instance && allowedInstanceCodes.includes(instance)
      ? (instance as InstanceCode)
      : 'kz'

  return result
}

function currentInstanceCode(): InstanceCode {
  return Env.get.INSTANCE ?? getInstanceCodeFromUrl()
}

type ObjectConfig<T> = {
  [code in InstanceCode]: T
}

type ArrayConfig = InstanceCode[]

function resolveInstance<T>(config: ObjectConfig<T>): T

function resolveInstance(config: ArrayConfig): boolean

function resolveInstance<T>(config: ArrayConfig | ObjectConfig<T>) {
  const code = currentInstanceCode()

  if (Array.isArray(config)) {
    return config.includes(code)
  }
  return config[code]
}

export { resolveInstance }
